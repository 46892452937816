import dynamic from 'next/dynamic';
import { components } from 'react-select';
import { MagnifyingGlass } from 'components/icons/MagnifyingGlass';
import { Close } from 'components/icons/Close';
import { FetchBotsParams, SearchParams } from 'api/bot/types';
import reactSelectStylesConfig from 'lib/react-select';
import { getBotsFilterOptions } from 'utils/hardcoded-data';
import useWindowWidth from 'hooks/useWindowWidth';
import { highlightText } from 'utils';
import CreatableSelect from 'react-select/creatable';
import { useRouter } from 'next/router';
import Link from 'next/link';
const ReactSelect = dynamic(() => import('react-select'), { ssr: false });

type Props = {
  onParamsChange: (val: any, key: keyof SearchParams | keyof FetchBotsParams) => void;
  query?: string;
  showSort?: boolean;
  source?: string;
};

export const BotGalleryFilters = ({ onParamsChange, query, showSort = false, source }: Props) => {
  const width = useWindowWidth();
  const router = useRouter();

  return (
    <div className="flex flex-col mt-4">
      <div className="flex gap-3 items-center justify-between">
        <CreatableSelect
          isClearable
          instanceId="bot-filter"
          classNames={{ valueContainer: () => 'sm' }}
          className={`${showSort ? 'w-auto min-w-[200px]' : 'w-full max-w-[400px]'} sm:flex-none z-40 mb-2`}
          // placeholder={width > 768 ? 'Search your Fantasies' : 'Search'}
          placeholder="Search your Fantasies"
          styles={{
            ...reactSelectStylesConfig,
            singleValue: base => ({
              ...base,
              color: '#CCC',
              fontSize: '14px',
              lineHeight: '20px',
              margin: 0
            }),
            valueContainer: base => ({
              ...base,
              overflow: 'hidden',
              padding: 0,
              fontSize: '14px',
            }),
            noOptionsMessage: (base) => ({
              textAlign: 'left',
              color: '#CCC',
              fontSize: '14px',
              lineHeight: '22px',
              padding: 8
            })
          }}
          components={{
            Option: ({ children, ...props }) => (
              <components.Option {...props}>
                {highlightText(props.selectProps.inputValue, children as string)}
              </components.Option>
            ),
            ValueContainer: ({ children, ...props }) => (
              <div className="flex items-center gap-2">
                <MagnifyingGlass className="text-primary size-5" />
                <components.ValueContainer {...props}>{children}</components.ValueContainer>
              </div>
            ),
            ClearIndicator: (props) => (
              <components.ClearIndicator {...props} className="!p-0">
                <Close className="size-4 mx-1 text-primary cursor-pointer" />
              </components.ClearIndicator>
            )
          }}
          options={getBotsFilterOptions()}
          formatCreateLabel={(inputValue) => `Search "${inputValue}"`}
          onChange={(val: any) => {
            if (!val) return;
            const isFromAutocomplete = getBotsFilterOptions().some(
              option => option.value.toLowerCase() === val.value.toLowerCase()
            );
            router.push({
              pathname: `/explore/${encodeURIComponent(val.value.toLowerCase())}`,
              query: {
                source: isFromAutocomplete ? 'autocomplete' : 'search'
              }
            });
          }}
          value={query ? { label: query, value: query } : null}
        />

        {showSort && (
          <ReactSelect
            instanceId="bot-sort"
            className="min-w-[120px] sm:flex-none mb-2 z-40"
            isSearchable={false}
            classNames={{ valueContainer: () => 'sm' }}
            placeholder="Sort by"
            styles={reactSelectStylesConfig}
            components={{
              SingleValue: ({ children, ...props }) => (
                <components.SingleValue {...props}>
                  {props.selectProps.menuIsOpen ? 'Sort by' : children}{' '}
                </components.SingleValue>
              )
            }}
            defaultValue={{ label: 'Featured', value: 'featured' }}
            onChange={(val: any) => onParamsChange(val.value, 'sort')}
            options={[
              { label: 'Featured', value: 'featured' },
              { label: 'Popular', value: 'popular' },
              { label: 'New', value: 'new' }
            ]}
          />
        )}
      </div>

      <div className="relative">
        <div className={`flex gap-2 overflow-x-auto no-scrollbar pb-2`}>
          {getBotsFilterOptions()
            .map(tag => ({
              ...tag,
              sortValue: Math.sin(new Date().getHours() + tag.value.length)
            }))
            .sort((a, b) => a.sortValue - b.sortValue)
            .map(({ value, label }) => (
            <Link
              key={value}
              href={{
                pathname: `/explore/${encodeURIComponent(value.toLowerCase())}`,
                query: {
                  source: 'pill'
                }
              }}
              className="shrink-0 px-2 py-1 text-xs rounded-full border border-primary
                text-stone-300 bg-primary/40 hover:bg-brand/60 transition-colors"
            >
              {label}
            </Link>
          ))}
        </div>
        <div className="absolute right-0 top-0 bottom-0 w-12 pointer-events-none
          bg-gradient-to-l from-bg1 to-transparent" />
      </div>
    </div>
  );
};