import Bugsnag from '@bugsnag/js';
import { showToast } from 'components/ui/CustomToast';

import filter from 'lodash/filter';
import some from 'lodash/some';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import get from 'lodash/get';

import { useAuthStore } from 'store/auth';
import { useModalsStore } from 'store/modals';

export const searchByKeys = (data: any[], searchKeys: string[], value: string) =>
  filter(data, (item) => some(searchKeys, (field) => includes(toLower(get(item, field)), toLower(value))));

export const cutString = (str: string, length: number) => {
  if (!str) return '';
  if (str?.length <= length) return str;
  return `${str?.slice(0, length)}...`;
};

export const generateArray = (length: number) => Array.from({ length }, (_, i) => i + 1);

export const getFormikError = (formik?: any, name?: string) =>
  formik && name && formik.touched[name] && formik.errors[name];

export const formatPrice = (price: number) =>
  (price / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

export const findOriginalPrice = (discountedPrice: number, discount: number) => discountedPrice / (1 - discount / 100);

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9\-]/g, '')
    .replace(/\-+/g, '-');

export const reportBug = ({ msg, prodToast, error }: { msg: string; error: any; prodToast?: boolean }) => {
  const env = process.env.NEXT_PUBLIC_STAGE_RELEASE || 'development';
  const status = error && Object.keys(error).includes('status') ? error.status : 'Unknown';
  const errorToDisplay = `[${status}] ${msg || error ? JSON.stringify(error) : "Oops! Something went wrong."}`;

  switch (env) {
    case 'development':
      showToast({ message: errorToDisplay, type: 'error' });
      console.log('ERROR ON DEVELOPMENT: ', JSON.stringify(error));
      // check if error contains string auth/invalid-custom-token
      console.log(`errorToDisplay: ${String(error).toLowerCase()} `);
      if (
        String(error).toLowerCase().includes('auth/invalid-custom-token') ||
        String(error).toLowerCase().includes('auth/popup-blocked')
      ) {
        // logout
        console.log(`🔑 User token invalid, logging out!`);
        if (typeof window !== 'undefined' && window.crush) {
          window.crush.signin();
        } else {
          console.log('❗️ window.crush is undefined');
        }
      }
      break;
    case 'staging':
      console.log('ERROR ON STAGING: ', JSON.stringify(error));
      // Intentionally SCREAM unhandled errors on staging.
      alert(errorToDisplay);
      // showToast({ message: (msg || "Oops! Something went wrong."), type: 'error' });
      break;
    case 'production':
      Bugsnag.notify(errorToDisplay);
      if (prodToast) showToast({ message: (msg || "Oops! Something went wrong."), type: 'error' });
      break;
    default:
      console.log('DEFAULT ERROR LOG: ', error);
  }
};

export const getRandomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export const highlightText = (searchQuery: string, text?: string) => {
  if (!text) return '';
  if (!searchQuery) return text;

  const escapedSearchQuery = searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedSearchQuery, 'gi');

  const highlightedText = text.replace(regex, (match) => `<mark>${match}</mark>`);

  return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

export const handleInsufficientBalance = (image: string) => {
  const { user } = useAuthStore.getState();
  const { setUpsellModal, setCreditsUpsellModal } = useModalsStore.getState();
  user?.is_premium
    ? setCreditsUpsellModal(image)
    : setUpsellModal({ image, type: 'out_of_credit', showRefreshCountdown: true });
};

export const firstLetterUppercase = (str: string) => {
  if (!str) return '';
  if (str.length === 1) return str.toUpperCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const mapArrayToObject = (arr: any[]) => {
  return Object.fromEntries(
    arr.map((item) => {
      const key = Object.keys(item)[0];
      return [key, item[key]];
    })
  );
};

export const copyToClipboard = async (str: string) => {
  try {
    await navigator.clipboard.writeText(str);
    showToast({ message: 'Link copied!', type: 'success' });
  } catch (err) {
    showToast({ message: 'Failed to copy to clipboard', type: 'error' });
  }
};
