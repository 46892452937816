import Link from 'next/link';

interface TagProps {
  tag: string;
  isCard?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const Tag = ({ tag, isCard = false, onClick }: TagProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick?.(e);
  };

  return (
    <Link
      href={`/explore/${encodeURIComponent(tag.toLowerCase())}?source=tag&${isCard ? 'type=cards' : ''}`}
      onClick={handleClick}
      className="text-xs text-stone-300 bg-black/40 hover:bg-brand/60
        whitespace-nowrap rounded-full border border-white/10
        py-1 px-3 backdrop-blur-sm transition-colors
        drop-shadow-[0_2px_2px_rgba(0,0,0,0.5)]"
    >
      {tag.toLowerCase()}
    </Link>
  );
};