import ReactSelect, { Props as SelectProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import reactSelectStylesConfig from 'lib/react-select';
import { getFormikError } from 'utils';
import { useState } from 'react';

import { components } from 'react-select';
import { highlightText } from 'utils';

type Props = SelectProps & {
  label?: string;
  formik?: any;
  inputClassName?: string;
  className?: string;
  isCreatable?: boolean;
};

export const Select = ({ label, formik, className, ...rest }: Props) => {
  const error = getFormikError(formik, rest.name);
  const [inputValue, setInputValue] = useState('');

  return (
    <div className={`input-field relative gap-1 ${className ? className : ''} `}>
      {label && <label className="ml-1">{label}</label>}
      {rest.isCreatable ?
        <CreatableSelect
          styles={reactSelectStylesConfig}
          classNames={{
            placeholder: () => 'text-sm italic',
            valueContainer: () => 'sm flex gap-2'
          }}
          inputValue={inputValue}
          onInputChange={(newValue) => setInputValue(newValue)}
          onKeyDown={(event: any) => {
            if (event.key === ',') {
              event.preventDefault();
              if (!inputValue) return;

              const value = inputValue.trim();
              if (value) {
                const newOption = { value, label: value };
                rest.onChange?.([...(Array.isArray(rest.value) ? rest.value : []), newOption], {
                  action: 'create-option',
                  option: newOption
                });
                setInputValue('');
              }
            }
          }}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: 'rgb(68 64 60)',  // wtf hack react-select https://stackoverflow.com/a/59314858/9688615
            }
          })}
          components={{
            Option: ({ children, ...props }) => (
              <components.Option {...props}>
                {highlightText(props.selectProps.inputValue, children as string)}
              </components.Option>
            ),
            MultiValue: ({ children, ...props }) => (
              <components.MultiValue
                {...props}
                components={{
                  Container: ({ children }) => (
                    <div className="relative text-center border border-brand/70 bg-brand/70 py-[1px] rounded-full px-2">
                      {children}
                    </div>
                  ),
                  Remove: ({ children, ...props }) => (
                    <components.MultiValueRemove {...props}>
                      <div className="absolute inset-0" />
                    </components.MultiValueRemove>
                  ),
                  Label: ({ children }) => <p className="text-white text-sm">{children}</p>
                }}
              >
                {children}
              </components.MultiValue>
            )
          }}
          {...rest}
        /> :
        <ReactSelect
          {...rest}
          styles={reactSelectStylesConfig}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: 'rgb(68 64 60)',  // wtf hack react-select https://stackoverflow.com/a/59314858/9688615
            }
          })}
          classNames={{
            placeholder: () => 'text-sm italic',
            valueContainer: () => 'sm flex gap-2'
          }}
        />
      }
      {error && <p className="error-msg">{error}</p>}
    </div>
  );
};
