import dynamic from 'next/dynamic';
import { fetchBots } from 'api/bot';
import { generateArray, highlightText } from 'utils';
import { useMutation } from 'react-query';
import { components } from 'react-select';
import React, { useState, useEffect } from 'react';
import { Bot, FetchBotsParams, SearchParams } from 'api/bot/types';
import reactSelectStylesConfig from 'lib/react-select';
import { getBotsFilterOptions, HOMEPAGE_CAROUSEL } from 'utils/hardcoded-data';
import { BotSkeleton } from 'components/skeletons/BotSkeleton';
import Link from 'next/link';
import useWindowWidth from 'hooks/useWindowWidth';
import { MagnifyingGlass } from 'components/icons/MagnifyingGlass';
import { Close } from 'components/icons/Close';
import { useAuthStore } from 'store/auth';
import { Carousel } from 'components/lists/lists/Carousel';
import { BotGalleryFilters } from 'components/filters/BotGalleryFilters';
import { BotCardItem } from '../cards/BotCardItem';
import router from 'next/router';


const ReactSelect = dynamic(() => import('react-select'), { ssr: false });

type Props = {
  isLoggedSSR?: boolean;
};

export const Bots = ({ isLoggedSSR }: Props) => {
  const { mutate, isLoading } = useMutation(fetchBots);
  const [bots, setBots] = useState<Bot[]>([]);
  const [maxPage, setMaxPage] = useState<number | null>(null);
  const { isLogged } = useAuthStore();
  const [params, setParams] = useState<FetchBotsParams>({
    mine: false,
    page: 0,
    size: 48,
    sort: 'featured',
    filter: ''
  });

  const isMaxExceeded = typeof maxPage === 'number' && bots.length >= maxPage;

  useEffect(() => {
    mutate(params, {
      onSuccess: ({ data, total, count }) => {
        setBots(data);
        setMaxPage(total ?? count ?? null);
      }
    });
  }, []);

  const handleParamsChange = (val: any, key: keyof FetchBotsParams | keyof SearchParams) => {
    if (key === 'q') {
      router.push({
        pathname: `/explore/${encodeURIComponent(val.toLowerCase())}`
      });
      return;
    }

    setParams((prev) => {
      const newParams = { ...prev, [key]: val, page: 0 };
      mutate(newParams, { onSuccess: (res) => setBots(res.data) });
      return newParams;
    });
  };

  return (
    <div className="flex flex-col pb-10">
      <div className="flex flex-col gap-4 text-center items-center">
        {!isLoggedSSR && (
          <>
            {!isLogged && (
              <>
                <div className="pt-6">
                  <h1 className="title">The Best AI Girlfriend and Chatbot</h1>
                  <p className="subtitle py-2">
                    Engaging backstories, impeccable memory, incredible experience.
                    <br /> Whether you&apos;re here for a flirt, fling, or roleplay, Crush&apos;s AI depth and
                    personality will blow you away.
                    <br /> Find your perfect companion today.
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {/* <Carousel
        title={HOMEPAGE_CAROUSEL.title}
        botCardIds={HOMEPAGE_CAROUSEL.ids}
      /> */}

      <BotGalleryFilters onParamsChange={handleParamsChange} showSort />

      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
        {bots.length
          ? bots.map((bot) => <BotCardItem key={bot.id} data={bot} />)
          : generateArray(8).map((i) => <BotSkeleton key={i} />)}
      </div>

      <div className="flex flex-col items-center gap-2 mt-6">
        <button
          className="btn btn-primary hover:text-brand hover:border-brand"
          disabled={isLoading || isMaxExceeded}
          onClick={() => {
            setParams((p) => {
              const newParams = { ...p, page: (p.page || 0) + 1 };
              mutate(newParams, { onSuccess: ({ data }) => setBots((p) => [...p, ...data]) });
              return newParams;
            });
          }}
        >
          {isMaxExceeded ? 'No more models available' : 'Load more'}
        </button>

        <span>
          or{' '}
          <Link href="/create" className="hover:text-brand gap-2" prefetch={false}>
            <u>Create your own!</u>
          </Link>
        </span>
      </div>
    </div>
  );
};
